<template>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="10" class="pt-4">
      <div class="flex otp-block justify-center">
        <template v-if="introducerLogo || onboardingLogo">
          <div v-if="onboardingLogo">
            <img :src="onboardingLogo" alt="Rello" style="max-height:68px;max-width:170px;display:block;" />
          </div>
          <div v-else-if="introducerLogo">
            <S3ImageGenerator :document="introducerLogo" :custom-class="'onboarding-image'" :is-base64="false" :key="introducerLogo" />
          </div>
          <div class="ds-wrapper ml-8 flex flex-col">
            <p class="text-xxs text-body">POWERED BY</p>
            <img src="@/assets/images/logo/rellopay-logo.svg" alt="Rello" v-if="appName === 'RelloPay'" style="height:52px;width:auto;" />
            <img src="@/assets/images/logo/flippay-logo.svg" alt="FlipPay" v-else-if="appName ==='FlipPay'" style="height:52px;width:auto;" />
          </div>
        </template>
        <template v-else>
          <div>
            <img src="@/assets/images/logo/rellopay-logo.svg" alt="Rello" v-if="appName === 'RelloPay'" style="height:auto;width:170px;" />
            <img src="@/assets/images/logo/flippay-logo.svg" alt="FlipPay" v-else-if="appName === 'FlipPay'" style="height:auto;width:170px;" />
          </div>
        </template>
      </div>
      <vs-card class="mt-3 md:mt-8 bg-transparent otp-block">
        <div slot="header" class="px-6 md:px-8 py-4 md:py-6 text-center">
          <feather-icon icon="UserIcon" svgClasses="stroke-current mid-blue w-8 h-8" class="mb-3" />
          <h3 class="text-xl font-normal leading-normal">Log in</h3>
        </div>
        <div class="px-6 lg:px-8 py-4 md:py-8 text-center">
          <p class="text-sm md:text-base font-light">To keep your application secure, we need to confirm it’s you by sending a one-time passcode to your email.</p>
          <div class="mt-6 md:mt-8">
            <div v-if="!showButton" class="payment-error-alert">
              <vs-alert>
                <p class="text-danger text-sm md:text-base font-normal px-0">Access to this request is currently not permitted. </p>
              </vs-alert>
            </div>
            <div v-if="email" vs-value="email" class="mt-4 w-full">
              <div class="text-dark-blue text-base mb-1">Email</div>
              <p class="text-base font-normal">{{ email }}</p>
            </div>
          </div>
          <vs-button color="primary" v-round v-if="showButton" class="w-full mt-6 md:mt-8 large" @click="sendCodeToOnboadMerchant">Send code</vs-button>
        </div>
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
import { clearLocalStorage } from "../../helper/localStorage.js";
import { mapActions } from "vuex";
import S3ImageGenerator from "@/views/onboarding/components/S3ImageGenerator";

export default {
  data() {
    return {
      showButton: true,
      email: "",
      requestId: this.$route.params.introducerRequestedId,
      isLogout: this.$route.query.logout,
      appName: process.env.VUE_APP_NAME || "FlipPay",
      introducerLogo : null,
      onboardingLogo: null,
    };
  },
  components: { S3ImageGenerator },
  methods: {
    ...mapActions("onboardMerchant", [
      "getOnboardRequestDetail", "sendVerificationTokenEmail"
    ]),

    getOnboardingDetail() {
      let requestId = this.requestId;
      this.getOnboardRequestDetail({introducerId: requestId}).then((res) => {
        clearLocalStorage();
        this.email = res.data.data.email;
        this.onboardingLogo = res.data.data.logo || null;
        this.introducerLogo = res.data.data.introducerLogo || null;

        if (this.onboardingLogo) {
          localStorage.setItem("onboardingLogo", this.onboardingLogo);
        }

        if (this.introducerLogo) {
          localStorage.setItem("introducerLogo", this.introducerLogo);
        }

        const status = ["Application in progress", "Pending", "Info Requested"];
        if (res.data.data && res.data.data.onboarding && !status.includes(res.data.data.onboarding.status)) {
          this.showButton = false;
        }
      });
    },

    sendCodeToOnboadMerchant() {
      let { requestId } = this;
      this.sendVerificationTokenEmail({ requestId }).then((res) => {
        this.$vs.notify({
          title: "OTP",
          text: "Verification code has been sent",
          color: "success",
          iconPack: "feather",
          position: "top-right",
        });
        localStorage.setItem("email", this.email);
        this.$router.push({ name: "onboarding-verify-code", params: { introducerId: requestId } });
      }).catch((ex) => {
        this.$vs.notify({
          title: "OTP",
          text: ex.data.message,
          color: "danger",
          iconPack: "feather",
          position: "top-right",
        });
      });
    },
  },

  created() {
    clearLocalStorage();
    this.getOnboardingDetail();
  }
};
</script>
